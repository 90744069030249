import { Link } from 'gatsby';
import React from 'react'

const Nav  = () => {

  const menuItems = [
    {
      url: '/about',
      title: 'About',
    },
    {
      url: '/blog',
      title: 'Blog',
    }
  ];

  return(
    <nav className="header__nav nav" id="main-nav">
      <ul className="nav__list">
        {menuItems.map((item, i) => (
          <li className="nav__list-item" key={i}>
            <Link to={ item.url } className="nav__list-link" activeClassName="nav__list-link--active">{item.title}</Link>
          </li>
        ))}
        <li className="nav__list-item ">
          <a href="mailto:anotherharriet@gmail.com" className="nav__list-link" activeClassName="nav__list-link--active">Contact</a>
        </li>
      </ul>
    </nav>
  )
}

export default Nav;