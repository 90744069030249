import React, { useState } from 'react'
import Nav from './Nav'
import Logo from '../../assets/images/logo.svg'

const Header  = () => {

  const [navIsOpen, setNavIsOpen] = useState(false);

  return(
    <header id="header" className={`container header ${navIsOpen ? 'open-nav' : ''}`} role="banner">
		  <div className="container__inner header__inner container__inner--flex" id="main-nav">

        <a className="header__logo" href="/" title="Another Harriet">
          <span className="screenreader-text">Another Harriet</span>
          <Logo />
        </a>

        <button className="header__burger burger nav-toggle" aria-labelledby="menu-label" onClick={() => setNavIsOpen(!!!navIsOpen)}>
          <span className="screenreader-text" id="menu-label">{navIsOpen ? 'Close Menu' : 'Open Menu'}</span>
          <span className="burger-menu">
            <div></div>
            <div></div>
            <div></div>
          </span>
        </button>

        <Nav></Nav>

		</div>
	</header>
  )
}

export default Header;