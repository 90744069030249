import React, {useState, useEffect} from 'react'

const Instagram  = () => {

  const feedUrl = 'https://www.juicer.io/api/feeds/anotherharrietblog?page=1&per=4'

  const [instagramData, setInstagramData] = useState('')

  useEffect(() => {
    fetch(feedUrl)
    .then(response => response.json()) // parse JSON from request
    .then(resultData => {
      console.log(resultData.posts)
      setInstagramData(resultData)
    })
  }, [])

  if(!instagramData){
    return null
  }

  return(
    
    <section className="container instagram" id="instagram">
      <div className="container__inner instagram__inner">
        <header className="instagram__header">
          <h6 className="instagram__header-subtitle">Instagram</h6>
          <h2 className="instagram__header-title">Let's get social</h2>
          <div className="instagram__header-link">Follow me over <a href="https://www.instagram.com/anotherharrietblog/" target="_blank" rel="noopener noreferrer">@anotherharrietblog</a></div>
        </header>

        <ul className="instagram__posts">
          {instagramData.posts.items.map((item, i) => (
            <div className="instagram__post" key={i}>
              <a href={item.full_url} className="instagram__link" target="_blank" rel="noopener noreferrer">
                <img src={item.image} alt="" />
              </a>
            </div>
          ))}
        </ul>
      </div>
    </section>
  )
}

export default Instagram;