import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import Instagram from "../Instagram";
import '@fontsource/open-sans'
import '@fontsource/playfair-display'

const Layout = ({ children }) => (
  <>
    <nav id="skip-to">
      <a href="#main-content">Skip to content</a>
      <a href="#main-nav">Skip to nav</a>
    </nav>

    <a href="#top" id="top"><span className="screenreader-text">Top of page</span></a>

    <Header />
    <main role="main" id="content">{children}</main>
    <Instagram />
    <Footer />
    <nav id="back-to-top">
      <a href="#top">Back to top</a>
    </nav>
  </>
);

export default Layout;
