import { useStaticQuery, graphql, Link } from 'gatsby';
import React from 'react'
import Newsletter from '../Newsletter'

const Footer  = () => {

  const legalPages = useStaticQuery(
    graphql`
      query LegalPagesQuery($uid: String) {
        allPrismicLegalPage(filter: { uid: { eq: $uid } }) {
          edges {
            node {
              uid
              data {
                title {
                  text
                }
              }
            }
          }
        }
      }
    `
  );

  const menuItems = [
    {
      url: '/about',
      title: 'About',
    },
    {
      url: '/blog',
      title: 'Blog',
    }
  ];

  const legalPageItems = legalPages.allPrismicLegalPage.edges
  let year = new Date()
  year = year.getFullYear()

  return(
    <footer className="container footer" role="contentinfo">
      <div className="container__inner footer__inner">
        <div className="footer__main">
          <Newsletter classes="footer__newsletter" title="Sign up to my newsletter" intro="Enter your email address here to receive regular round-ups of articles shared and exclusive content:"></Newsletter>
          <div className="footer__links footer-links">
            <div className="footer-links__col">
              <h6 className="footer-links__col-title">
                Navigate
              </h6>
              <div className="footer-links__col-content">
                <ul className="footer-links__col-list">
                  {menuItems.map((item, i) => (
                    <li className="footer-links__col-list-item" key={i}>
                      <Link to={ item.url } className="footer-links__col-list-link">{item.title}</Link>
                    </li>
                  ))}
                  <li className="footer-links__col-list-item">
                    <a href="mailto:anotherharriet@gmail.com" className="footer-links__col-list-link">Contact</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="footer-links__col">
              <h6 className="footer-links__col-title">Contact</h6>
              <div className="footer-links__col-content">
                <a href="mailto:anotherharriet@gmail.com" className="footer-links__col-link">anotherharriet@gmail.com</a>
              </div>
            </div>
          </div> 
        </div>
      </div>
      <div className="footer__sub">
        <span className="footer__sub-attribution">
          &copy; {year} Another Harriet
        </span>
        <nav className="footer__sub-nav footer-sub-nav">
          <ul className="footer-sub-nav__list">
            {legalPageItems.map((item, i) => {
              return (
                <li className="footer-sub-nav__list-item" key={i}>
                  <Link to={`/${item.node.uid}`} className="footer-sub-nav__list-link">{ item.node.data.title[0].text }</Link>
                </li>
                )
              })}
          </ul>
        </nav>
      </div>
	</footer>
  )
}

export default Footer;