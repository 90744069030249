import React, { useState } from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp'

const NewsletterForm = () => {

  const [email, setEmail] = useState("")
  const [submitted, setSubmitted] = useState(false)

  const errorHandling = (data) => {
    // your error handling
  }

  const handleSubmit = () => {
    addToMailchimp(email).then((data) => {

      if (data.result === "error") {
        errorHandling(data)
      } else {
        setSubmitted(true)
      }
    })
  }

  return(
    <>
    {submitted ? (
      <div className="newsletter-success">
        Thanks for signing up!
      </div> 
    ) : (
      <form onSubmit={handleSubmit(email)}>
        <div>
          <div className="newsletter-form-row">
            <label htmlFor="email" className="screenreader-text">Email Address  <span className="asterisk">*</span></label>
            <input 
              type="email"
              name="email" 
              className="required email" 
              id="email" 
              placeholder="Email" 
              onChange={(e) => setEmail(e.target.value)}
              />
            <button type="submit" name="subscribe" id="mc-embedded-subscribe" className="button button--secondary"><span>Sign up</span></button>
          </div>
        </div>
      </form>
    )}
    </>
  )
}


export default NewsletterForm;