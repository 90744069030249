import React from 'react'
import NewsletterForm from './NewsletterForm';

const Newsletter  = ({subtitle, title, intro, classes}) => {

  let sectionClasses = 'newsletter '
  sectionClasses += classes

  return(
    <div className={ sectionClasses }>
      <h6 className="newsletter__subtitle">{ subtitle }</h6>
      <h2 className="newsletter__title">{ title }</h2>
      <p className="newsletter__intro">{ intro }</p>
      <NewsletterForm />

    </div>
  )
}

export default Newsletter;